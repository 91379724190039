import { Ionicons } from '@expo/vector-icons';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import * as React from 'react';

import Colors from '../constants/Colors';

export function HomeTabBarIcon(props) {
  return (
    <Image source={require('../assets/images/football.png')}
    style={styles.navigation_bar}/>
  );
}

export function ResourceTabBarIcon(props) {
  return (
    <Image source={require('../assets/images/robot-dev.png')}
    style={styles.navigation_bar}/>
  );
}

const styles = StyleSheet.create({
  navigation_bar: {
    flex: 1,
    width: 30,
    height: 30,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10,
  }
});
